const chkServizi = Array.from (
  document.querySelectorAll ('div .voci-scheda.servizi input')
);

const chkLingue = Array.from (
  document.querySelectorAll ('div .voci-scheda.lingue input')
);

const chkMood = Array.from (
  document.querySelectorAll ('div .voci-scheda.mood input')
);

const chkGroups = [
  {name: 'servizi', checks: chkServizi},
  {name: 'lingue', checks: chkLingue},
  {name: 'mood', checks: chkMood},
];

export default chkGroups;
