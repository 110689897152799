const MONTHS = [
  {it: 'GEN', en: 'JAN', de: 'JAN'},
  {it: 'FEB', en: 'FEB', de: 'FEB'},
  {it: 'MAR', en: 'MAR', de: 'MAR'},
  {it: 'APR', en: 'APR', de: 'APR'},
  {it: 'MAG', en: 'MAY', de: 'MAI'},
  {it: 'GIU', en: 'JUN', de: 'JUN'},
  {it: 'LUG', en: 'JUL', de: 'JUL'},
  {it: 'AGO', en: 'AUG', de: 'AUG'},
  {it: 'SET', en: 'SEP', de: 'SEP'},
  {it: 'OTT', en: 'OCT', de: 'OKT'},
  {it: 'NOV', en: 'NOV', de: 'NOV'},
  {it: 'DIC', en: 'DEC', de: 'DEZ'},
];

function getLanguage () {
  const parts = window.location.pathname.split ('/');

  if (parts.length < 2 || parts[1] === '') {
    return DEFAULT_LANGUAGE;
  }

  let lang = parts[1];

  if (!['it', 'en', 'de'].includes (lang)) {
    lang = 'en';
  }

  return lang;
}

export default function _meteoWidget (days) {
  const elMeteo = document.getElementById ('kffc__meteo--widget');

  if (!elMeteo) return false;

  fetch (
    'https://api.weatherapi.com/v1/forecast.json?key=34b06e85a78c41f4821103412220803&q=45.5980351,10.0643982&days=3&aqi=no&alerts=no'
  )
    .then (response => response.json ())
    .then (data => {
      const forecast = data.forecast.forecastday;

      forecast.forEach (fore => {
        const lang = getLanguage ();
        const monthNum = new Date (fore.date).getMonth ();
        const dayNum = new Date (fore.date).getDate ();
        const monthLet = MONTHS[monthNum][lang];
        const elWrap = document.createElement ('div');
        const elDay = document.createElement ('div');
        const elMon = document.createElement ('div');
        const img = document.createElement ('img');

        elWrap.className = 'kffc__meteo--wrapper';
        elDay.className = 'kffc__meteo--day';
        elMon.className = 'kffc__meteo--month';
        elDay.innerText = dayNum;
        elMon.innerText = monthLet;

        const imgname = new URL ('https:' + fore.day.condition.icon).pathname
          .split ('/')
          .pop ();
        img.src = `https://goat.keyformat.it/static/franciacorta/meteo/icons/${imgname}`;

        elWrap.appendChild (img);
        elWrap.appendChild (elDay);
        elWrap.appendChild (elMon);
        elMeteo.appendChild (elWrap);
      });
    });
}
