import {filterCallbackFactory, getAllGliderEls} from './sliderFilterFunctions';
import chkGroups from './chkGroups';

function getValArray (chkArray) {
  const ret = [];

  chkArray.forEach (el => {
    if (el.checked) {
      ret.push (el.value);
    }
  });

  if (ret.length > 0) {
    return ret;
  }

  return null;
}

export default function addChkListeners () {
  const _allGliderEls = getAllGliderEls ();
  const filterCallback = filterCallbackFactory (_allGliderEls);

  const getHandler = filterName => {
    const _handler = e => {
      window.FCMAP_DISPATCH ({
        type: 'toggle_type',
        payload: {
          type: filterName,
          value: e.target.value,
          filterCallback,
        },
      });
    };

    return _handler;
  };

  chkGroups.forEach (group => {
    group.checks.forEach (el => {
      el.addEventListener ('change', getHandler (group.name));
    });
  });
}
