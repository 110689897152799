import {getAllPlacesIds} from './placesSelectors';

export const getAllGliderEls = () => {
  return Array.from (document.querySelectorAll ('.related-box.glider-slide'));
};

export const getAllGliderItemIds = gliderEls => {
  const ids = gliderEls.map (el => el.dataset.placeId);

  return ids;
};

export const getGliderIdsToRemove = (gliderIds, placeIds) => {
  return gliderIds.filter (id => !placeIds.includes (id));
};

export const getGliderIdsToAdd = (gliderIds, placeIds) => {
  return placeIds.filter (id => !gliderIds.includes (id));
};

export const getGliderItemsByPlacesIds = (gliderEls, placeIds) => {
  return gliderEls.filter (el => {
    return placeIds.includes (parseInt (el.dataset.placeId, 10));
  });
};

export const filterCallbackFactory = allGliderEls => {
  return function _filterCallback (places) {
    const placeIds = getAllPlacesIds (places);
    const finalGliderEls = getGliderItemsByPlacesIds (allGliderEls, placeIds);

    let _gliderels = [...allGliderEls];

    for (c = 0; c < 20; c++) {
      try {
        _gliderels = getAllGliderEls ();
        _gliderels.map ((el, i) => {
          try {
            FC_GLIDER.removeItem (i);
          } catch (e) {}
        });
      } catch (e) {}
    }

    finalGliderEls.forEach (el => FC_GLIDER.addItem (el.cloneNode (true)));
  };
};
