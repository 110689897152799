import './lightbox';

export function initMaps () {
  btnMappaEl = document.getElementById ('kf-map-open');
  btnMappaAdditionals = Array.from (
    document.querySelectorAll ('.kf-cta__map-open')
  );

  const myGallery = GLightbox ({
    elements: [
      {
        content: `<div id="kffc-smartmap__container--lightbox"></div>`, // read more in the API section
      },
    ],
    autoplayVideos: false,
    width: '94vw',
    height: '94vh',
    //test: true,
    touchNavigation: false,
    touchFollowAxis: false,
    closeButton: true,
    draggable: false,
    skin: 'kffc-map__lightbox',
  });

  myGallery.on ('open', () => {
    window.FCMAP_SHOW ({
      containerId: 'kffc-smartmap__container--lightbox',
      showUI: true,
      zoom: 12,
      places: ['all'],
      canFilterPlaces: true,
      showEdge: true,
      showFCStreet: false,
    });
  });

  if (btnMappaEl) {
    btnMappaEl.addEventListener ('click', e => {
      myGallery.open ();
    });
  }

  if (btnMappaAdditionals.length > 0) {
    btnMappaAdditionals.forEach (btnMappaAdditional =>
      btnMappaAdditional.addEventListener ('click', e => {
        myGallery.open ();
      })
    );
  }

  if (document.getElementById ('kffc-smartmap__container')) {
    window.FCMAP_SHOW ();
  }
}
