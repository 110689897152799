import {pagePreferiti, iconsPreferiti} from './bookmarks';
import addChkListeners from './mapFiltersHandlers';
import {initMaps} from './map';
import meteoWidget from './meteo';

function initApplication () {
  initMaps ();
  addChkListeners ();
  pagePreferiti ();
  iconsPreferiti ();
  meteoWidget ();
}

window.addEventListener ('load', event => {
  setTimeout (() => initApplication (), 100);
});
