export const pagePreferiti = () => {
  if (!window.location.href.includes ('preferiti')) return;

  const risultatiGateEl = document.getElementById ('kf-listbycards__container');

  const schedeEls = Array.from (document.querySelectorAll ('.kf-list-card'));

  schedeEls.forEach (el => el.remove ());

  const bookmarksLoadedCallback = (bookmarks, categorie) => {
    bookmarks.forEach (bookmark => {
      console.log (bookmark);
      const {slug, title, categoria, img} = bookmark;
      const newSchedaEl = schedeEls[0].cloneNode (true);
      const elCat = newSchedaEl.querySelector ('.kf-list-card__cat');
      const elName = newSchedaEl.querySelector ('.kf-list-card__name');
      const elRemove = newSchedaEl.querySelector ('.kf-list-card__action');
      const elImg = newSchedaEl.querySelector ('img');
      const categorie = JSON.parse (sessionStorage.getItem ('FC_PLACES')).categorie;

      const _urlObj = new URL(document.location.href);
      const _hostname = _urlObj.hostname;
      const _lang = _urlObj.pathname.split('/')[1];
      const _placeCat = categoria[0];
      const _catName = categorie[_placeCat].name[_lang];
      const _catSlug = categorie[_placeCat].slug[_lang];
      const url = `https://${_hostname}/${_lang}/${_catSlug}/${slug}`;

      elImg.addEventListener ('click', e => (window.location.href = url));
      elName.addEventListener ('click', e => (window.location.href = url));
      elRemove.addEventListener ('click', e => {
        FCMAP_DISPATCH ({type: 'bookmark_remove', payload: slug});
        newSchedaEl.remove ();
      });

      elImg.src = img;
      elImg.style.width = '120px';
      elName.style.cursor = 'pointer';
      elName.innerHTML = title;
      elCat.innerHTML = _catName;

      risultatiGateEl.appendChild (newSchedaEl);
    });
  };

  FCMAP_GET_BOOKMARKS (bookmarksLoadedCallback);
};

export const iconsPreferiti = () => {
  const iconsEls = Array.from (document.querySelectorAll ('.salva'));

  if (iconsEls.length === 0) return;

  const bookmarksLoadedCallback = bookmarks => {
    iconsEls.forEach (el => {
      const placeId = parseInt (el.dataset.placeId, 10);
      const place = window.FCMAP_PLACES.filter (
        place => place.id === placeId
      )[0];
      let isBookm = bookmarks.filter (book => book.id === placeId).length === 1;

      el.style.cursor = 'pointer';

      if (isBookm) {
        el.classList.add ('saved');
      }

      el.addEventListener ('click', e => {
        if (isBookm) {
          FCMAP_DISPATCH ({type: 'bookmark_remove', payload: place.slug});
          el.classList.remove ('saved');
          isBookm = false;
        } else {
          FCMAP_DISPATCH ({type: 'bookmark_add', payload: place.slug});
          el.classList.add ('saved');
          isBookm = true;
        }
      });
    });
  };

  FCMAP_GET_BOOKMARKS (bookmarksLoadedCallback);
};
